import VueI18n from 'vue-i18n'

const locale = (process.env.VUE_APP_SUPPORT_LANGUAGE) ? process.env.VUE_APP_SUPPORT_LANGUAGE : 'th'

if (locale === 'th') {
	require('dayjs/locale/th')
} else {
	require('dayjs/locale/en')
}

const i18n = new VueI18n({
	locale: locale ,
	silentFallbackWarn: true ,
	silentTranslationWarn : true,
});

function loadCommonMessages(locale) {
	return import(/* webpackChunkName: "i18n_[request]" */ './'+locale+".js").then(messages => {
		const loaded = {};
		for(const key in messages.default) {
			/*
			const msgKey = key.startsWith("common.") ? key : "common."+key;
			loaded[msgKey] = messages.default[key];
			*/
			loaded[key] = messages.default[key];
		}
		i18n.setLocaleMessage(locale,loaded);
	});
}
loadCommonMessages(locale);
export default i18n;
