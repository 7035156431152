import { isStringEmpty } from "../stringUtil";
import ServerRequestError from "./ServerRequestError.js"

export default class ApiError extends Error {
	constructor(original,errorResponse) {
		super(errorResponse.message);
		this.original = original;
		this.code = errorResponse.code;
		this.message = errorResponse.message;
		this.debug = errorResponse._debug_exception;
		this.timestamp = errorResponse.timestamp;

		this.url = (original.config) ? original.config.url : null
		this.method = (original.config) ? original.method : null

		this.errorResponse = errorResponse
	}

	static getStringFieldErrors(fieldErrors) {
		if (!fieldErrors)
			return undefined
		const errors = [];
		for(const field in fieldErrors) {
			errors.push(fieldErrors[field].join(', '))
		}
		return errors.join(', ')
	}

	static getDisplayErrorMessage(component,error) {
		if (!error) {
			return component.$t('common.error.unspecify')
		}
		if (typeof error === 'string') {
			return error
		}
		if ('fieldErrors' in error) {
			return component.$t('common.error.validate_error')
		}
		if (error instanceof ServerRequestError) {
			return component.$t('common.error.server_connect')
		}

		if (!isStringEmpty(error.message))
			return error.message
		else
			return component.$t('common.error.unspecify')
	}

	getErrorTitle() {
		let title = "API ERROR ["+this.code+":"+this.timestamp+"]";
		if (this.method) {
			title += " "+this.method
		}
		if (this.url) {
			title += " "+this.url
		}
		return title;
	}
}
