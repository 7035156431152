// Import Components to use in globals
import {
	Icon ,
	Spin ,
	Select,
	Button,Form,Input,Alert,
	Modal, Card,
	message,notification,
	Dropdown,
	Menu
} from "ant-design-vue"
import Vue from "vue"
import PageHeader from "./layout/PageHeader.vue"
import MyRouterLink from "@components/common/MyRouterLink.vue"
import { abilitiesPlugin } from '@casl/vue';
import abilities from '../authorize/abilities';

Vue.use(Icon)

Vue.use(Spin)
Vue.use(Select)

Vue.use(Button)
Vue.use(Form)
Vue.use(Input)
Vue.use(Alert)
Vue.use(Dropdown)
Vue.use(Menu)

Vue.use(Modal)
Vue.use(Card)

Vue.component("my-router-link",MyRouterLink)
Vue.component("my-page-header",PageHeader)

Vue.use(abilitiesPlugin, abilities)

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

notification.config({
	duration : 3 ,
	placement: 'bottomRight',
})
