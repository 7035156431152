import store from '@store/store'
import MainLayout from '@layouts/MainLayout.vue'
import NonLoginLayout from '@layouts/NonLoginLayout.vue'
import Timeout from '@views/errors/Timeout.vue'

export default [
  {
    path : '/part' ,
    component : MainLayout ,
    name : 'part' ,
    redirect : {name:'part/list'} ,
    meta: {
      authRequired: true ,
      breadcrumb : {
        title : "breadcrumb.part"
      } ,
      menu : {
        open : 'menu.drone' ,
        select : 'menu.drone.part'
      }
    } ,
    children : [
      {
        path : '' ,
        name : 'part/list' ,
        component : () => lazyLoadView(import('@views/part/PartList.vue')) ,
        meta : {
          breadcrumb : false
        }
      } ,
      {
        path : 'create' ,
        name : 'part/create' ,
        component : () => lazyLoadView(import('@views/part/PartCreate.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create"
          } ,
          permission : {
            module : 'part' ,
            action : 'create' ,
          }
        } ,

      } ,
      {
        path : 'import' ,
        name : 'part/import' ,
        component : () => lazyLoadView(import('@views/part/PartImport.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.part.import"
          } ,
          permission : {
            module : 'part' ,
            action : 'import' ,
          }
        }
      } ,

      {
        path : ':id' ,
        name: 'part/view',
        component : () => lazyLoadView(import('@views/part/PartViewLayout.vue')) ,
        redirect : {name : 'part/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'partName'
          }
        } ,
        children: [
          {
            path: '' ,
            name: 'part/base' ,
            component : () => lazyLoadView(import('@views/part/PartBase.vue')) ,
          } ,
          {
            path: 'usages' ,
            name: 'part/usages' ,
            component : () => lazyLoadView(import('@views/part/PartUsages.vue')) ,
          } ,
          {
            path: 'lots' ,
            name: 'part/lots' ,
            component : () => lazyLoadView(import('@views/part/PartLots.vue')) ,
          } ,
        ] ,
        permission : {
          module : 'part' ,
          action : 'read' ,
        }
      } ,
      {
        path : ':id/update' ,
        name: 'part/update' ,
        component : () => lazyLoadView(import('@views/part/PartUpdate.vue')) ,
        meta : {
          permission : {
            module : 'part' ,
            action : 'update'
          } ,
          breadcrumb : {
            title : "breadcrumb.common.update" ,
            parent : {
              name : 'part/view',
              titleVar : 'partName'
            }
          }
        }
      } ,
    ]
  } ,
  {
    path : '/drone-model' ,
    component : MainLayout ,
    name : 'drone-model' ,
    redirect : {name:'drone_model/list'} ,
    meta: {
      authRequired: true ,
      breadcrumb : {
        title : "breadcrumb.drone_model"
      } ,
      menu : {
        open : 'menu.drone' ,
        select : 'menu.drone.drone_model'
      }
    } ,
    children : [
      {
        path : '' ,
        name : 'drone_model/list' ,
        component : () => lazyLoadView(import('@views/drone_model/ModelList.vue')) ,
        meta : {
          breadcrumb : false
        }
      } ,
      {
        path : 'create' ,
        name : 'drone_model/create' ,
        component : () => lazyLoadView(import('@views/drone_model/ModelCreate.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create"
          },
          permission : {
            module : 'droneModel' ,
            action : 'create' ,
          }
        }
      } ,
      {
        path : ':id' ,
        name: 'drone_model/view',
        component : () => lazyLoadView(import('@views/drone_model/ModelViewLayout.vue')) ,
        redirect : {name : 'drone_model/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'modelName'
          }
        } ,
        permission : {
          module : 'droneModel' ,
          action : 'read' ,
        } ,
        children: [
          {
            path: 'base' ,
            name: 'drone_model/base' ,
            component : () => lazyLoadView(import('@views/drone_model/ModelBase.vue')) ,
          } ,
          {
            path: 'parts' ,
            name: 'drone_model/parts' ,
            component : () => lazyLoadView(import('@views/drone_model/ModelParts.vue')) ,
          } ,
          {
            path: 'parts/manage' ,
            name: 'drone_model/parts_manage' ,
            component : () => lazyLoadView(import('@views/drone_model/ModelPartsManage.vue')) ,
            meta : {
              permission : {
                module : 'droneModel' ,
                action : 'update'
              } ,
              breadcrumb : {
                title : 'breadcrumb.drone_model.parts_manage' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            }
          } ,
          {
            path: 'lots' ,
            name: 'drone_model/lots' ,
            component : () => lazyLoadView(import('@views/drone_model/ModelLots.vue')) ,
          } ,
          {
            path : 'lots/create' ,
            name: 'drone_model/lot_create' ,
            component : () => lazyLoadView(import('@views/drone_model/ModelLotCreate.vue')) ,
            meta : {
              permission : {
                module : 'droneLot' ,
                action : 'create'
              } ,
              breadcrumb : {
                title : 'breadcrumb.drone_model.lots_create',
              } ,
              hideTab : true ,
              hideAction : true ,
            }
          } ,
        ]
      } ,
      {
        path : ':id/update' ,
        name: 'drone_model/update' ,
        component : () => lazyLoadView(import('@views/drone_model/ModelUpdate.vue')) ,
        meta : {
          permission : {
            module : 'droneModel' ,
            action : 'update'
          } ,
          breadcrumb : {
            title : 'breadcrumb.common.update' ,
            parent : {
              name : 'drone_model/view',
              titleVar : 'modelName'
            }
          } ,
        }
      } ,
      {
        path : ':id(\\d+)/duplicate' ,
        name: 'drone_model/duplicate' ,
        component : () => lazyLoadView(import('@views/drone_model/ModelDuplicate.vue')) ,
        meta : {
          permission : {
            module : 'droneModel' ,
            action : 'update'
          } ,
          breadcrumb : {
            title : 'breadcrumb.drone_model.duplicate' ,
            parent : {
              name : 'drone_model/view',
              titleVar : 'modelName'
            }
          } ,
        }
      }
    ]
  } ,
  {
    path : '/drone-lot' ,
    component : MainLayout ,
    name : 'drone-lot' ,
    redirect : {name:'drone_model/list'} ,
    meta: {
      authRequired: true ,
      breadcrumb : {
        ignore : true,
        parent : [
          {
            title : 'breadcrumb.drone_model' ,
            name : 'drone_model/list'
          } ,
          {
            titleVar : 'modelName',
            name : 'drone_model/lots' ,
            routeParams : [
              {
                name : 'id',
                valueVar : 'modelId'
              }
            ]
          } ,

        ]
      },
      menu : {
        open : 'menu.drone' ,
        select : 'menu.drone.drone_model'
      }
    } ,
    children : [
      {
        path : ':id' ,
        name: 'drone_lot/view',
        component : () => lazyLoadView(import('@views/drone_lot/DroneLotViewLayout.vue')) ,
        redirect : {name: 'drone_lot/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'lotName' ,
          } ,
          permission : {
            module : 'droneLot' ,
            action : 'read'
          } ,
        } ,
        children: [
          {
            path: 'base' ,
            name: 'drone_lot/base' ,
            component : () => lazyLoadView(import('@views/drone_lot/DroneLotBase.vue')) ,
          } ,
          {
            path: 'parts' ,
            name: 'drone_lot/parts' ,
            component : () => lazyLoadView(import('@views/drone_lot/DroneLotParts.vue')) ,
          } ,
          {
            path: 'parts/manage' ,
            name: 'drone_lot/parts_manage' ,
            component : () => lazyLoadView(import('@views/drone_lot/DroneLotPartsManage.vue')) ,
            meta : {
              permission : {
                module : 'droneLot' ,
                action : 'update'
              } ,
              breadcrumb : {
                title : 'breadcrumb.drone_lot.parts_manage' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            }
          } ,
          {
            path: 'usages' ,
            name: 'drone_lot/usages' ,
            component : () => lazyLoadView(import('@views/drone_lot/DroneLotUsages.vue')) ,
            meta : {
              permission : {
                module : 'drone' ,
                action : 'listAll'
              } ,
            }
          } ,
          {
            path: 'import',
            name: 'drone_lot/import_drones' ,
            component : () => lazyLoadView(import('@views/drone_lot/DroneLotImportDrones.vue')) ,
            meta : {
              permission : {
                module : 'drone' ,
                action : 'create'
              } ,
              breadcrumb : {
                title : 'breadcrumb.drone_lot.import_drones' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            }
          }
        ]
      } ,
      {
        path: ':id(\\d+)/update' ,
        name: 'drone_lot/update' ,
        component : () => lazyLoadView(import('@views/drone_lot/DroneLotUpdate.vue')) ,
        meta : {
          permission : {
            module : 'droneLot' ,
            action : 'update'
          } ,
          breadcrumb : {
            title : 'breadcrumb.common.update' ,
            parent : {
              name : 'drone_lot/view',
              titleVar : 'lotName'
            }
          } ,
        }
      } ,
      {
        path: ':id(\\d+)/duplicate' ,
        name: 'drone_lot/duplicate' ,
        component : () => lazyLoadView(import('@views/drone_lot/DroneLotDuplicate.vue')) ,
        meta : {
          permission : {
            module : 'droneLot' ,
            action : 'create'
          } ,
          breadcrumb : {
            title : 'breadcrumb.drone_lot.duplicate' ,
            parent : {
              name : 'drone_lot/view',
              titleVar : 'lotName'
            }
          } ,
        }
      } ,
    ]
  } ,
  {
    path : '/admin' ,
    name : 'admin',
    component : MainLayout ,
    meta : {
      authRequired : true,
      menu : {
        open : 'menu.admin' ,
      } ,
    } ,
    children : [
      {
        path : 'service' ,
        name : 'admin/service_list' ,
        component : () => lazyLoadView(import('@views/service/ServiceList.vue')) ,
        props : () => {
          return {
            showOnfieldOnly : false
          }
        } ,
        meta : {
          permission : {
            module : 'service' ,
            action : 'list'
          } ,
          menu : {
            select : 'menu.service'
          } ,
          breadcrumb : {
            title : "breadcrumb.admin.service"
          } ,
        }
      } ,
      {
        path : 'ticket' ,
        name : 'admin/ticket_list' ,
        component : () => lazyLoadView(import('@views/ticket/TicketList.vue')) ,
        meta : {
          permission : {
            module : 'ticket' ,
            action : 'list',
          } ,
          menu : {
            select : 'menu.ticket'
          } ,
          breadcrumb : {
            title : "breadcrumb.admin.ticket"
          } ,
        }
      } ,
      {
        path : 'manufacture' ,
        name : 'admin/manufacture' ,
        component : () => lazyLoadView(import("@views/manufacture/ManufactureList.vue")) ,
        meta : {
          permission : {
            module : 'manufacture' ,
            action : 'list' ,
          } ,
          menu : {
            select : 'menu.admin.manufacture'
          } ,
          breadcrumb : {
            title : "breadcrumb.admin.manufacture"
          } ,
        }
      } ,
      {
        path : 'drone' ,
        name : 'admin/drone_list' ,
        component : () => lazyLoadView(import('@views/drone/DroneList.vue')) ,
        meta : {
          permission : {
            module : 'drone' ,
            action : 'list'
          } ,
          menu : {
            select : 'menu.admin.drone'
          } ,
          breadcrumb : {
            title : "breadcrumb.admin.drone"
          } ,
        } ,
      } ,
      {
        path : 'drone-change-request' ,
        name : 'admin/drone_change_request' ,
        component : () => lazyLoadView(import('@views/change_request/DroneChangeRequestList.vue')) ,
        meta : {
          permission : {
            module : 'drone' ,
            action : 'listAll'
          } ,
          menu : {
            select : 'menu.admin.drone_change_request'
          } ,
          breadcrumb : {
            title : "breadcrumb.admin.drone_change_request"
          } ,
        } ,
      } ,
      {
        path : 'drone/import-register' ,
        name : 'admin/drone_import_register' ,
        component : () => lazyLoadView(import('@views/drone/DroneImportRegistered.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.admin.drone.import_register" ,
            parent : {
              name : 'admin/drone_list',
              title : "breadcrumb.admin.drone"
            }
          } ,
          permission : {
            module : 'drone' ,
            action : 'register' ,
          } ,
          menu : {
            select : 'menu.admin.drone' ,
          } ,
        } ,
      } ,
    ]
  } ,
  {
    path: '/drone-change-request',
    component: MainLayout,
    name: 'drone_change_request' ,
    redirect : {name : 'admin/drone_change_request'} ,
    meta: {
      authRequired: true,
      menu: {
        select: 'menu.admin.drone_change_request'
      },
    },
    children: [
      {
        path : ':request_id',
        name : 'drone_change_request/view' ,
        component : () => lazyLoadView(import("@views/change_request/DroneChangeRequestView.vue")),
        meta : {
          breadcrumb : {
            titleVar : 'droneSerialNo' ,
            parent : {
              name : 'drone_change_request',
              title : "breadcrumb.admin.drone_change_request" ,
              routeParams : [{name:'request_id',value : undefined}],
            }
          }
        } ,
      }
    ]
  },
  {
    path : '/drone',
    component : MainLayout,
    name : 'drone' ,
    redirect : {name : 'drone/search'} ,
    meta : {
      authRequired : true,
      menu : {
        select : 'menu.drone.drone'
      } ,
    } ,
    children : [
      {
        path : '' ,
        name : 'drone/search' ,
        component : () => lazyLoadView(import('@views/drone/DroneViewSerialLayout.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.drone" ,
          } ,
        }
      } ,
      {
        path : ':serial_no' ,
        name : 'drone/view' ,
        component : () => lazyLoadView(import("@views/drone/DroneViewSerialLayout.vue")) ,
        redirect : {name : 'drone/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'droneSerialNo' ,
            parent : {
              name : 'drone/search',
              title : "breadcrumb.drone" ,
              routeParams : [{name:'serial_no',value : undefined}],
            }
          }
        } ,
        children : [
          {
            path : '',
            name : 'drone/base' ,
            component : () => lazyLoadView(import("@views/drone/DroneBase.vue")) ,
            meta : {
              showAction : true,
            }
          } ,
          {
            path : 'histories' ,
            name : "drone/histories",
            component : () => lazyLoadView(import("@views/drone/DroneHistories.vue")) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.drone.histories' ,
              } ,
            } ,
          } ,
          {
            path : 'parts',
            name : "drone/parts",
            component : () => lazyLoadView(import("@views/drone/DroneParts.vue")) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.drone.parts' ,
              } ,
            } ,
          } ,
          {
            path : 'ticket/create' ,
            name : 'drone/ticket_create' ,
            component : () => lazyLoadView(import("@views/drone/DroneCreateTicket.vue")) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.drone.ticket.create' ,
              } ,
            } ,
          } ,
          {
            path : 'ticket/recent' ,
            name : 'drone/ticket_recent',
            component : () => lazyLoadView(import('@views/drone/DroneTicketsRecent.vue')) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.drone.ticket.recent' ,
              } ,
            } ,
          } ,
          {
            path : 'ticket/progress' ,
            name : 'drone/ticket_progress',
            component : () => lazyLoadView(import('@views/drone/DroneTicketsProgress.vue')) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.drone.ticket.progress' ,
              } ,
            } ,

          } ,
          {
            path : 'ticket/pending' ,
            name : 'drone/ticket_pending',
            component : () => lazyLoadView(import('@views/drone/DroneTicketsPending.vue')) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.drone.ticket.pending' ,
              } ,
            } ,
          } ,
          {
            path : 'register' ,
            name : 'drone/register' ,
            component : () => lazyLoadView(import("@views/drone/DroneRegister.vue")) ,
            meta : {
              permission : {
                module : 'drone' ,
                action : 'register'
              } ,
              breadcrumb : {
                title : 'breadcrumb.drone.register'
              } ,
            }
          } ,
          {
            path : 'change-register' ,
            name : 'drone/change_register' ,
            component : () => lazyLoadView(import("@views/drone/DroneChangeRegister.vue")) ,
            meta : {
              permission : {
                module : 'drone' ,
                action : 'changeRegister'
              } ,
              breadcrumb : {
                title : 'breadcrumb.drone.change_register'
              } ,
            }
          } ,
          {
            path : "service/create" ,
            name : 'drone/service_create' ,
            component : () => lazyLoadView(import('@views/drone/DroneCreateService.vue')) ,
            meta : {
              breadcrumb : {
                title : "breadcrumb.drone.service.create" ,
              } ,
              permission : {
                module : 'service' ,
                action : 'create' ,
              } ,
            } ,
          } ,
          {
            path : "service/create-refer/:id(\\d+)" ,
            name : 'drone/service_create_refer' ,
            component : () => lazyLoadView(import('@views/drone/DroneCreateServiceRefer.vue')) ,
            meta : {
              breadcrumb : {
                title : "breadcrumb.drone.service.create_refer" ,
              } ,
              permission : {
                module : 'service' ,
                action : 'create' ,
              } ,
            } ,
          } ,
          {
            path : "service/recent" ,
            name : 'drone/service_recent' ,
            component : () => lazyLoadView(import('@views/drone/DroneServicesRecent.vue')) ,
            meta : {
              breadcrumb : {
                title : "breadcrumb.drone.service.recent" ,
              } ,
            } ,
          } ,
        ]
      }
    ] ,
  },
  {
    path : '/ticket' ,
    component : MainLayout ,
    name : 'ticket' ,
    redirect : {name : 'ticket/list'} ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : "breadcrumb.ticket"
      } ,
      menu : {
        select : 'menu.ticket'
      }
    } ,
    children : [
      {
        path : '',
        name : 'ticket/list' ,
        component : () => lazyLoadView(import('@views/ticket/TicketList.vue')) ,
      } ,
      {
        path : 'create' ,
        name : 'ticket/create' ,
        component : () => lazyLoadView(import('@views/ticket/TicketCreatePage.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create"
          } ,
          menu : {
            select : 'menu.ticket.create' ,
          } ,
          permission : {
            module : 'ticket' ,
            action : 'create' ,
          }
        }
      } ,
      {
        path : 'create-general' ,
        name : 'ticket/create_general' ,
        component : () => lazyLoadView(import('@views/ticket/TicketCreateGeneral.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create"
          } ,
          permission : {
            module : 'ticket' ,
            action : 'create' ,
          }
        }
      } ,
      {
        path : 'create-service/:id(\\d+)' ,
        name : 'ticket/create_service' ,
        component : () => lazyLoadView(import('@views/ticket/TicketCreateService.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create"
          } ,
          permission : {
            module : 'ticket' ,
            action : 'create' ,
          }
        }
      } ,
      {
        path : 'log-file/:id(\\d+)' ,
        name : 'ticket/log_file' ,
        component : () => lazyLoadView(import('@views/ticket/TicketLogFile.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.ticket.log_file" ,
            parent : {
              name : 'ticket/view',
              routeParams : [{
                name : 'id',
                valueVar : 'ticketId',
              }] ,
              titleVar : 'ticketNo'
            }
          }
        }
      } ,
      {
        path : ':id(\\d+)' ,
        name : 'ticket/view' ,
        component : () => lazyLoadView(import('@views/ticket/TicketView.vue')) ,
        meta : {
          breadcrumb : {
            titleVar : 'ticketNo'
          }
        } ,
      } ,
      {
        path : ':id(\\d+)/update' ,
        name : 'ticket/update' ,
        component : () => lazyLoadView(import('@views/ticket/TicketUpdate.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.update" ,
            parent : {
              name : 'ticket/view',
              titleVar : 'ticketNo'
            } ,
            permission : {
              module : 'ticket' ,
              action : 'update' ,
            }
          }
        }
      } ,
    ]
  } ,
  {
    path : '/service' ,
    component : MainLayout ,
    name : 'service' ,
    redirect : {name : 'drone/search'} ,
    meta : {
      authRequired : true,
      menu : {
        select : 'menu.drone.drone' ,
      }
    } ,
    children : [
      {
        path : 'create' ,
        name : 'service/create' ,
        component : () => lazyLoadView(import('@views/service/ServiceCreatePage.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create" ,
            parent : [
              {
                name : "drone/search" ,
                title : "breadcrumb.service" ,
	            } ,
            ]
          } ,
          menu : {
            select : 'menu.service.create' ,
          } ,
          permission : {
            module : 'service' ,
            action : 'create' ,
          }
        }
      } ,
      {
        path : ':id(\\d+)' ,
        name : 'service/view' ,
        component : () => lazyLoadView(import('@views/service/ServiceViewLayout.vue')) ,
        redirect : {name : 'service/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'serviceNo' ,
            parent : [
              {
                name : "drone/search" ,
                title : "breadcrumb.drone" ,
	              routeParams : [{name:'serial_no',value : undefined}],
              } ,
              {
                name : "drone/view" ,
                routeParams : [{name:'serial_no',valueVar : 'droneSerialNo'}] ,
                titleVar : "droneSerialNo" ,
              }
            ]
          } ,
          permission : {
            module : 'service' ,
            action : 'read' ,
          }
        } ,
        children :[
          {
            path : '' ,
            name : 'service/base' ,
            component : () => lazyLoadView(import('@views/service/ServiceBase.vue'))
          } ,
          {
            path : 'pre-service' ,
            name : 'service/pre_service' ,
            component : () => lazyLoadView(import('@views/service/PreService.vue'))
          } ,
          {
            path : 'service-job' ,
            name : 'service/service_job' ,
            component : () => lazyLoadView(import('@views/service/ServiceJob.vue'))
          } ,
          {
            path : 'pre-delivery' ,
            name : 'service/pre_delivery' ,
            component : () => lazyLoadView(import('@views/service/PreDelivery.vue'))
          } ,
          {
            path : 'payment' ,
            name : 'service/payment' ,
            component : () => lazyLoadView(import('@views/service/Payment.vue'))
          } ,
          {
            path : 'return' ,
            name : 'service/return' ,
            component : () => lazyLoadView(import('@views/service/ServiceReturn.vue'))
          } ,
          {
            path : 'claim',
            name : 'service/claim',
            component : () => lazyLoadView(import('@views/service/ServiceClaim.vue')) ,
          }
        ]
      } ,
      {
        path : ':id(\\d+)/update' ,
        name : 'service/update' ,
        component : () => lazyLoadView(import('@views/service/ServiceUpdate.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.update" ,
            parent : [
              {
                name : "drone/search" ,
                title : "breadcrumb.drone" ,
	              routeParams : [{name:'serial_no',value : undefined}],
              } ,
              {
                name : "drone/view" ,
                routeParams : [{name:'serial_no',valueVar : 'droneSerialNo'}] ,
                titleVar : "droneSerialNo" ,
              } ,
              {
                name : 'service/view',
                titleVar : 'serviceNo'
              }
            ]
          },
        }
      } ,
      {
        path : ':id(\\d+)/refer' ,
        name : 'service/refer' ,
        component : () => lazyLoadView(import('@views/service/ServiceCreateRefer.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.service.create_refer" ,
            parent : [
              {
                name : "drone/search" ,
                title : "breadcrumb.drone" ,
	              routeParams : [{name:'serial_no',value : undefined}],
              } ,
              {
                name : "drone/view" ,
                routeParams : [{name:'serial_no',valueVar : 'droneSerialNo'}] ,
                titleVar : "droneSerialNo" ,
              } ,
              {
                name : 'service/view',
                titleVar : 'serviceNo'
              }
            ]
          },
        }
      }
    ]
  } ,
  {
    path : '/onfield',
    component : MainLayout,
    name : 'onfield',
    redirect : {name : 'onfield/create'} ,
    meta : {
      authRequired : true,
      menu : {
        select : 'menu.onfield.create',
      },
      onfieldAccessRequired : true,
    },
    children: [
      {
        path : 'create' ,
        name : 'onfield/create' ,
        component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldCreatePage.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.common.create" ,
            parent : [
              {
                name : "onfield/create" ,
                title : "breadcrumb.service.onfield" ,
              } ,
            ]
          } ,
          menu : {
            select : 'menu.onfield.create' ,
          } ,
          permission : {
            module : 'service' ,
            action : 'create' ,
          }
        }
      } ,
      {
        path : 'service_list' ,
        name : 'onfield/resume' ,
        component : () => lazyLoadView(import('@views/service/ServiceList.vue')) ,
        props : () => {
          return {
            showOnfieldOnly : true
          }
        } ,
        meta : {
          permission : {
            module : 'service' ,
            action : 'list'
          } ,
          menu : {
            select : 'menu.onfield.resume'
          } ,
          breadcrumb : {
            title : "breadcrumb.admin.service_onfield"
          } ,
        }
      } ,
      {
        path : ':id(\\d+)' ,
        name : 'onfield/view' ,
        component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldViewLayout.vue')) ,
        redirect : {name : 'onfield/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'serviceNo' ,
            parent : [
              {
                name : "onfield/create" ,
                title : "breadcrumb.service.onfield" ,
              } ,
            ]
          } ,
          permission : {
            module : 'service' ,
            action : 'read' ,
          }
        } ,
        children :[
          {
            path : '' ,
            name : 'onfield/base' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldBase.vue'))
          } ,
          {
            path : 'bind-ticket' ,
            name : 'onfield/bind_ticket' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldBindTicket.vue'))
          },
          {
            path : 'pre-service' ,
            name : 'onfield/pre_service' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldPreService.vue')) ,
          } ,
          {
            path : 'service-job' ,
            name : 'onfield/service_job' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldServiceJob.vue')) ,
          } ,
          {
            path : 'claim' ,
            name : 'onfield/claim' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldClaimList.vue')) ,
          } ,
          {
            path : 'pre-delivery' ,
            name : 'onfield/pre_delivery' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldPreDeliveryPage.vue')) ,
          },
          {
            path : 'return' ,
            name : 'onfield/return' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldReturnPage.vue')) ,
          },
          {
            path : 'cancel' ,
            name : 'onfield/cancel' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldCancelPage.vue'))
          },
          {
            path : 'close' ,
            name : 'onfield/close' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldClosePage.vue'))
          },
          {
            path : 'cost-estimate' ,
            name : 'onfield/cost_estimate' ,
            component : () => lazyLoadView(import('@views/service/onfield/ServiceOnfieldCostEstimatePage.vue'))
          },
        ]
      } ,
    ]
  },
  {
    path : "/report" ,
    component : MainLayout ,
    name : "report" ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : "breadcrumb.report"
      } ,
      menu : {
        open : 'menu.report' ,
      }
    } ,
    children : [
      {
        path : 'part-usage' ,
        name : 'report/part_usage' ,
        component : () => lazyLoadView(import('@views/report/PartUsageReport.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.report.part_usage"
          } ,
          menu : {
            select : "menu.report.part_usage"
          }
        }
      } ,
      {
        path : 'claim-request' ,
        name : 'report/claim_request',
        component : () => lazyLoadView(import('@views/report/ClaimRequestReport.vue')) ,
        meta : {
          breadcrumb : {
            title : "breadcrumb.report.claim_request"
          } ,
          menu : {
            select : "menu.report.claim_request"
          }
        }
      }
    ]
  } ,
  {
    path : '/firmware' ,
    component : MainLayout ,
    name : 'firmware' ,
    redirect : {name : 'firmware/list'} ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : "breadcrumb.firmware"
      } ,
      menu : {
        open : 'menu.system' ,
        select : 'menu.system.firmware'
      }
    } ,
    children : [
      {
        path : '' ,
        name : 'firmware/list' ,
        component : () => lazyLoadView(import('@views/firmware/FirmwareList.vue')) ,
        meta : {
          breadcrumb : false
        }
      },
    ]
  } ,
  {
    path : '/company' ,
    component : MainLayout ,
    name : 'company' ,
    redirect : {name : 'company/list'} ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : "breadcrumb.company"
      } ,
      menu : {
        open : 'menu.system' ,
        select : 'menu.system.company'
      }
    } ,
    children : [
      {
        path : '' ,
        name : 'company/list' ,
        component : () => lazyLoadView(import('@/src/views/company/CompanyListView.vue')) ,
        meta : {
          breadcrumb : false
        }
      },
      {
        path : 'create' ,
        name : 'company/create' ,
        component : () => lazyLoadView(import('@views/company/CompanyCreate.vue')) ,
        meta : {
          permission : {
            module : 'company',
            action : 'create' ,
          },
          breadcrumb : {
            title : "breadcrumb.common.create"
          }
        }
      } ,
      {
        path : ':id(\\d+)' ,
        name : 'company/view' ,
        component : () =>lazyLoadView(import('@views/company/CompanyViewLayout.vue')) ,
        redirect : {name : 'company/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'companyName'
          }
        } ,
        children: [
          {
            path : '' ,
            name : 'company/base' ,
            component : () => lazyLoadView(import('@views/company/CompanyBaseView.vue')) ,
          } ,
          {
            path: 'users' ,
            name: 'company/users' ,
            component : () => lazyLoadView(import('@views/company/CompanyUsers.vue')) ,
          } ,
          {
            path: 'access_management' ,
            name: 'company/access_management' ,
            component : () => lazyLoadView(import('@views/company/CompanyAccessManagement.vue')) ,
          },
          {
            path : 'users/create' ,
            name: 'company/user_create' ,
            component : () => lazyLoadView(import('@views/company/CompanyUserCreate.vue')),
            meta : {
              permission : {
                module : 'company' ,
                action : 'update' ,
                paramMaps : {
                  companyId : 'id'
                }
              } ,
              breadcrumb : {
                title : 'breadcrumb.company.users.create' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            }
          } ,
          {
            path: 'tickets' ,
            name: 'company/tickets' ,
            component : () => lazyLoadView(import('@views/ticket/TicketSummaryPage.vue')) ,
            props : (route) => {
              return {
                filterName : 'report_by_company_id' ,
                filterValue : route.params.id ,
                progressMoreLink : {name : "company/tickets_progress",params : {id : route.params.id}} ,
                recentMoreLink : {name : "company/tickets_recent",params : {id : route.params.id}} ,
                pendingMoreLink : {name : "company/tickets_pending",params : {id : route.params.id}} ,
              }
            } ,
            meta : {
              permission : {
                module : 'ticket' ,
                action : 'listAll'
              } ,
            }
          } ,
          {
            path : 'tickets/recent' ,
            name : 'company/tickets_recent',
            component : () => lazyLoadView(import('@views/company/CompanyTicketsRecent.vue')) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.company.tickets.recent' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            } ,

          } ,
          {
            path : 'tickets/progress' ,
            name : 'company/tickets_progress',
            component : () => lazyLoadView(import('@views/company/CompanyTicketsProgress.vue')) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.company.tickets.progress' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            } ,

          } ,
          {
            path : 'tickets/pending' ,
            name : 'company/tickets_pending',
            component : () => lazyLoadView(import('@views/company/CompanyTicketsPending.vue')) ,
            meta : {
              breadcrumb : {
                title : 'breadcrumb.company.tickets.pending' ,
              } ,
              hideTab : true ,
              hideAction : true ,
            } ,

          }
        ]
      } ,
      {
        path : ':id(\\d+)/update' ,
        name: 'company/update' ,
        component : () => lazyLoadView(import('@views/company/CompanyUpdate.vue')) ,
        meta : {
          permission : {
            module : 'company' ,
            action : 'update' ,
            paramMaps : {
              companyId : 'id'
            }
          } ,
          breadcrumb : {
            title : 'breadcrumb.common.update' ,
            parent : {
              name : 'company/view',
              titleVar : 'companyName'
            }
          } ,
        }
      }
    ]

  } ,
  {
    path : '/user' ,
    name : "user",
    component : MainLayout,
    redirect : {name : 'user/base'} ,
    meta: {
      authRequired: true ,
      menu : {
        open : 'menu.system' ,
        select : 'menu.system.company'
      } ,
      breadcrumb : {
        ignore : true,
        parent : [
          {
            title : 'breadcrumb.company' ,
            name : 'company/list'
          } ,
          {
            titleVar : 'companyName',
            name : 'company/users' ,
            routeParams : [
              {
                name : 'id',
                valueVar : 'companyId'
              }
            ]
          } ,
        ]
      }
    } ,
    children : [
      {
        path : ':id(\\d+)?' ,
        name : "user/view" ,
        component : () => lazyLoadView(import('@views/user/UserViewLayout.vue')) ,
        redirect : {name : 'user/base'} ,
        meta : {
          breadcrumb : {
            titleVar : 'userName' ,
          }
        } ,
        children : [
          {
            path : '' ,
            name : 'user/base' ,
            component : () => lazyLoadView(import('@views/user/UserBase.vue'))
          } ,
          {
            path : "/user/change-password" ,
            name : 'user/change_password',
            component : () => lazyLoadView(import('@views/user/ChangePassword.vue')) ,
            meta : {
              hideTab : true,
              hideAction : true,
              breadcrumb : {
                title : 'breadcrumb.user.change_password'
              }
            }
          } ,
        ]
      } ,
      {
        path : ':id(\\d+)/update' ,
        name: 'user/update' ,
        component : () => lazyLoadView(import('@views/user/UserUpdate.vue')) ,
        meta : {
          permission : {
            module : 'user' ,
            action : 'update' ,
            paramMaps : {
              userId : 'id'
            }
          } ,
          breadcrumb : {
            title : "breadcrumb.common.update" ,
            parent : {
              name : 'user/view',
              titleVar : 'userName'
            }
          }
        }
      } ,

    ]
  } ,
  {
    path : '/privacy-policy' ,
    name : 'user/privacy_policy' ,
    component : () => lazyLoadView(import('@views/user/PrivacyPolicy.vue')) ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : false,
      } ,
    }
  } ,
  {
    path : '/consent' ,
    component : MainLayout ,
    name : 'consent' ,
    redirect : {name : 'consent/base'} ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : false,
      } ,
    } ,
    children : [
      {
        path : '',
        name : 'consent/base' ,
        component : () => lazyLoadView(import('@views/consent/Consent.vue')) ,
      }
    ]
  } ,
  {
    path : '/dashboard' ,
    component : MainLayout ,
    name : 'dashboard' ,
    redirect : {name : 'dashboard/base'} ,
    meta : {
      authRequired : true,
      breadcrumb : {
        title : false,
      } ,
      menu : {
        select : 'menu.dashboard'
      }
    } ,
    children : [
      {
        path : '',
        name : 'dashboard/base' ,
        component : () => lazyLoadView(import('@views/dashboard/DashboardBase.vue')) ,
      },
      {
        path:':mode',
        name:'dashboard/extend',
        component: () => lazyLoadView(import('@views/dashboard/DashboardExtendService.vue')),
      }
    ]
  } ,
  {
    path: '/' ,
    component: MainLayout ,
    redirect: {name : 'dashboard'} ,
    meta: {
      authRequired: true ,
    } ,
  },
  {
    path : '/site' ,
    component: NonLoginLayout ,
    redirect: '/site/login' ,
    meta: {
      authRequired: false ,
    } ,
    children : [
      {
        path: 'login',
        name: 'login',
        component: () => lazyLoadView(import('@views/site/Login.vue')),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/isLoggedIn']) {
              // Redirect to the home page instead
              next(routeTo.query.redirect || {name : 'dashboard'})
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      } , {
        // TODO Forgot Password View
        path : 'forgot-password' ,
        name : 'forgot-password' ,
        redirect: underConstruction
      } ,
      {
        path : 'privacy_policy' ,
        name : 'privacy' ,
        component : () => lazyLoadView(import('@views/privacy_policy/PrivacyPolicy.vue')) ,
      } ,
    ]
  } ,
  {
    path : '/errors' ,
    component : () => {
      if (store.getters['auth/isLoggedIn'])
        return Promise.resolve(MainLayout)
      else
        return Promise.resolve(NonLoginLayout)
     } ,
    meta: {
      authRequired: false ,

    } ,
    children:[
      {
        path: 'timeout' ,
        name: 'error_timeout' ,
        component: Timeout,
        props: true ,
        meta : {
          breadcrumb : {
            hide : true,
          }
        }
      } ,
      {
        path: '403' ,
        name: 'error_forbidden' ,
        component: () => import(/* webpackChunkName: "errors" */ '@views/errors/Error403.vue') ,
        props : true ,
        meta : {
          breadcrumb : {
            hide : true,
          }
        }
      } ,
      {
        path: '404' ,
        name: 'error_notfound' ,
        component: () => import(/* webpackChunkName: "errors" */ '@views/errors/Error404.vue') ,
        props : true ,
        meta : {
          breadcrumb : {
            hide : true,
          }
        }
      } ,
      {
        path: '500' ,
        name: 'error_unexpected' ,
        component: () => import(/* webpackChunkName: "errors" */ '@views/errors/Error500.vue') ,
        props : true ,
        meta : {
          breadcrumb : {
            hide : true,
          }
        }
      } ,
      {
        path: 'under-construction' ,
        name: 'error_underconstruction' ,
        component: () => import(/* webpackChunkName: "errors" */ '@views/errors/UnderConstruction.vue') ,
        props : true ,
        meta : {
          breadcrumb : {
            hide : true,
          }
        }
      } ,
    ]
  } ,
  {
    path : '/callback' ,
    component : () => {
      if (store.getters['auth/isLoggedIn'])
        return Promise.resolve(MainLayout)
      else
        return Promise.resolve(NonLoginLayout)
    } ,
    meta: {
      authRequired: false ,
    } ,
    children : [
      {
        path : 'line',
        name : 'callback/line',
        component: () => lazyLoadView(import('@views/callback/LineCallback.vue'))
      }
    ]
  } ,
  {
    path : '*' ,
    component : () => {
      if (store.getters['auth/isLoggedIn'])
        return Promise.resolve(MainLayout)
      else
        return Promise.resolve(NonLoginLayout)
     } ,
    meta: {
      authRequired: false ,
    } ,
    children : [
      {
        path: '' ,
        component: () => import(/* webpackChunkName: "errors" */ '@views/errors/Error404.vue') ,
        props : () => {
          return {
            location : window.location.href
          }
        } ,
        meta : {
          breadcrumb : {
            hide : true,
          }
        }
      }
    ]
  } ,
];





// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  return AsyncView
  /*
  Ignore for now, because we need component guard
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/PageLoading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 10,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: Timeout,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 5000,
  })

  return Promise.resolve({
    functional: true,
    render(h, {
      data,
      children
    }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
  */
}

// Redirect Page to under construction (Attach from query)
function underConstruction(to) {
  const fullUrl = to.fullPath
  return {
    name : 'error_underconstruction',
    query : {from : fullUrl}
  }

}
