import axios from 'axios'

export const state = {
	forms: [],
}
export const mutations = {
	UPDATE_FORMS(state, payload) {
		state.forms = payload.forms
	},
}
export const actions = {
	init({ dispatch }) {
		dispatch('fetchForms')
	},
	async fetchForms({ commit, rootGetters }) {
		if (!rootGetters['auth/isLoggedIn']) return Promise.resolve()
		return axios
			.get('/api/services/form-revision')
			.then((response) => {
				const forms = response.data.data
				commit('UPDATE_FORMS', { forms: forms })
			})
			.catch((error) => {
				console.log('Found error during request forms ', error)
			})
	},
}
