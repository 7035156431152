import Vue from "vue"
import axios from "axios"
import debounce from "lodash/debounce"
import { PART_MODE } from "@utils/droneUtil"
import { SERVICE_PART_MODE, serviceModeToText, HOUR_TO_MINUTE } from "@utils/serviceUtil"

export const state = {
	parts : [] ,
	firmwareSets : [] ,
	manHourPrice: 300 ,
}
export const getters = {
	getPartById: (state) => (id) => {
		if (!id || id <= 0) {
			return {}
		} else {
			const part = state.parts.find(part => part.id == id);
			if (part)
				return {...part}
			else
				return {}
		}
	} ,
	getPartsInfo: (state) => (parts = [], canClaim = false) => {
		if(!parts) return []

		return parts.map(part => {
		if (part.new_part_mode === PART_MODE.CUSTOM || !part.mode === SERVICE_PART_MODE.REMOVE) return null

			const newPartInfo = state.parts.find(statePart => {
				return statePart.id === part.new_part_id
			});
			if (!newPartInfo) return null

			const claimPart = part.num_request ? part.num_request : 0
			const claimApprovedPart = part.num_approved ? part.num_approved : 0
			const remark = newPartInfo.remark ? parseFloat(newPartInfo.remark) : 0
			const pricePerUnit = newPartInfo.price_per_unit ? parseFloat(newPartInfo.price_per_unit) : 0
			const partNumChange = part.num_change ? part.num_change : 0
			const feePerUnit = (remark / HOUR_TO_MINUTE) * state.manHourPrice
			const priceWithClaimRequest = (partNumChange - claimPart) * pricePerUnit
			const priceWithClaimApproved = (partNumChange - claimApprovedPart) * pricePerUnit

			let oldPartName = ''
			if(part.old_part_mode === PART_MODE.CUSTOM){
				oldPartName = part.old_part_name
			}
			else if(part.mode === SERVICE_PART_MODE.CHANGE && part.old_part_mode === PART_MODE.SELECT){
				const oldPartInfo =  state.parts.find(part => part.id === part.old_part_id);
				oldPartName = oldPartInfo ? oldPartInfo.name : oldPartName
			}

			return {
				id: part.id,
				partSku: newPartInfo.sku,
				partName: newPartInfo.name,
				totalTime: partNumChange * remark,
				finalTime: (partNumChange - claimPart) * remark,
				finalApprovedTime: (partNumChange - claimApprovedPart) * remark,
				priceAllClaimUnit: pricePerUnit * claimPart,
				priceAllClaimApproveUnit: pricePerUnit * claimApprovedPart,
				oldPartName,
				pricePerUnit,
				price: pricePerUnit * partNumChange,
				priceWithClaimRequest,
				priceWithClaimApproved,
				feePerUnit,
				fee: feePerUnit * partNumChange,
				partNumChange,
				action: part.mode,
				canClaim,
				sendForClaim: !!part.num_request,
				description: serviceModeToText(part.mode).concat(newPartInfo.name),
				requestClaimNum: claimPart,
			}
		}).filter(part => part)
	},
	getLatestFirmwareBySet : (state) => (id) => {
		if (!id || id <= 0) {
			return false
		}
		const set = state.firmwareSets.find(set => set.id == id)
		if (set && set.firmwares && set.firmwares.length > 0) {
			return {
				...set.firmwares[0]
			}
		} else {
			return false
		}
	} ,
	getFirmwaresBySet : (state) => (id) => {
		if (!id || id <= 0) {
			return []
		} else {
			const set = state.firmwareSets.find(set => set.id == id)
			if (set) {
				return [...set.firmwares]
			} else {
				return []
			}
		}
	} ,
	getFirmwareById : (state) => (id) => {
		if (!id || id <= 0) {
			return {}
		}
		for(const firmwareSet of state.firmwareSets) {
			for(const firmware of firmwareSet.firmwares) {
				if (firmware.id == id)
					return {...firmware}
			}
		}
		return {}
	}
}
export const mutations = {
	GET_FROM_LS(state) {
		state.parts = Vue.ls.get('drone.parts',[]);
		state.firmwareSets = Vue.ls.get('drone.firmwareSets',[])
	} ,
	UPDATE_PARTS(state,payload) {
		// Store only for 1 day
		state.parts = payload.parts;
		Vue.ls.set('drone.parts',payload.parts,30*60*1000)
	} ,
	UPDATE_FIRMWARE_SET(state,payload) {
		state.firmwareSets = payload.firmwareSets
		Vue.ls.set('drone.firmwareSets',payload.firmwareSets,30*60*1000)
	}
}
export const actions = {
	init({commit,dispatch}) {
		commit('GET_FROM_LS')
		dispatch('fetchParts')
		dispatch('fetchFirmwareSets')
	} ,
	async _fetchParts({commit,rootGetters}){
		if (!rootGetters['auth/isLoggedIn'])
			return Promise.resolve();
		else
			return axios.get("/api/parts/search",{
				params : {
					search : {pageSize : 1000}
				}
			}).then((response) => {
				const parts = response.data.data.parts;
				parts.forEach((p) => {
					p.searchContent = (p.sku+"&&"+p.name+"&&"+p.name_en).toLowerCase()
				});
				commit('UPDATE_PARTS',{parts : parts})
			}).catch((error) => {
				console.log("Found error during request parts ",error)
			})
	} ,
	async _fetchFirmwareSets({commit,rootGetters}) {
		if (!rootGetters['auth/isLoggedIn'])
			return Promise.resolve();
		else
			return axios.get("/api/firmwares/search",{params : {mode:"with_firmwares"}}).then((response)=>{
				const firmwareSets = response.data.data.firmware_sets
				commit('UPDATE_FIRMWARE_SET',{firmwareSets})
			}).catch((error) => {
				console.log("Found error during request firmware sets ",error)
			})
	} ,
	fetchFirmwareSets : debounce(function({dispatch}){
		dispatch('_fetchFirmwareSets')
	},300,{leading:false}),
	fetchParts : debounce(function({dispatch}){
		dispatch('_fetchParts');
	},300,{leading:false})
}
