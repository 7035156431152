<i18n locale="en" lang="yaml">
pageLoading.tip : "Loading ..., Please wait"
</i18n>
<i18n locale="th" lang="yaml" >
pageLoading.tip : "กำลัง Load ข้อมูล, กรุณารอสักครู่ ..."
</i18n>

<template>
	<ConfigProvider :locale="locale">
		<a-layout :class="[layoutCssClasses,device]">
			<a-drawer
				v-if="showMenuAsDrawer"
				placement="left"
				:visible="!sideMenuDrawerCollapsed"
				:closable="false"
				:width="250"
				wrap-class-name="tunjai-drawer-wrapper"
				@close="onDrawerClose">
				<LayoutSideMenu
					:is-drawer="true" />
			</a-drawer>
			<a-layout-sider
				v-show="!showMenuAsDrawer"
				v-model="siderCollapsed"
				:width="250"
				:trigger="null"
				class="tunjai-sider-wrapper"
				collapsible>
				<LayoutSideMenu
					:menu-collapsed="siderCollapsed"/>
			</a-layout-sider>

			<a-layout>
				<LayoutHeader />
				<a-layout-content id="tunjai_layout_content" :class="['tunjai-layout-content']">
					<a-back-top :target="backTopFn" />

					<a-spin
						wrapper-class-name="tunjai-page-loading"
						:spinning="pageLoading && blockPageLoading"
						size="large"
						:tip="$t('pageLoading.tip')">
						<div id="tunjai_content">
							<MyBreadcrumb v-if="!pageError" :title-vars="breadcrumbParams" />
							<MyRouterViewWrapper @page-error="onPageError" />
						</div>
					</a-spin>
				</a-layout-content>
			</a-layout>

		</a-layout>

	</ConfigProvider>
</template>

<script>
import {Drawer,Layout,BackTop} from "ant-design-vue"
import LayoutMixin from "@mixins/LayoutMixin.vue"
import LayoutHeader from "@components/layout/LayoutHeader.vue"
import LayoutSideMenu from "@components/layout/LayoutSideMenu.vue"
import MyBreadcrumb from "@components/layout/MyBreadcrumb.vue"
import MyRouterViewWrapper from "@components/layout/MyRouterViewWrapper.vue"
import { mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
	components : {
		LayoutHeader ,
		LayoutSideMenu , MyBreadcrumb ,
		MyRouterViewWrapper ,
		"a-drawer" : Drawer ,
		"a-layout" : Layout,"a-layout-sider" : Layout.Sider ,
		"a-layout-content" : Layout.Content ,
		"a-back-top" : BackTop
	},
	mixins : [LayoutMixin],
	data(){
		return {
			pageError : false,
			backTopFn : function() {
					return document.getElementById('tunjai_layout_content')
			}
		}
	} ,
	computed : {
		...mapState('app',[
			'pageLoading',
			'blockPageLoading',
			'breadcrumbParams'
			]) ,
		showMenuAsDrawer() {
			return this.isMobile || this.isTablet
		} ,
		siderCollapsed : {
			get() {
				return this.sideMenuCollapsed
			} ,
			set(value) {
				return this.collapsedSideMenu(value)
			}
		} ,
		layoutCssClasses() {
			return {
				'tunjai-layout' : true,
				'sidemenu-collapsed': this.sideMenuCollapsed
			}
		}
	} ,
	mounted() {
		this.toggleBodyClass('tunjai-body',true)
	} ,
	destroyed() {
		this.toggleBodyClass('tunjai-body',false)
	} ,
	methods : {
		...mapActions('app',['collapsedSideMenuDrawer']) ,
		onDrawerClose() {
			this.collapsedSideMenuDrawer(true)
		} ,
		onPageError() {
			this.pageError = true;
		} ,
		toggleBodyClass(className,isAdd) {
			if (isAdd) {
				document.body.classList.add(className)
				if (this.$router.currentRoute.name != 'consent/base') {
						axios.get("/api/users/consent")
						.then((response) =>
						response.data.data.isConsent ? null  : this.$router.push({name: 'consent'}))
				}
			} else {
				document.body.classList.remove(className)
			}
		}
	} ,


}
</script>
<style lang="less">
.tunjai-page-loading {
	min-height : 300px;

	.ant-spin-blur {
		opacity: 0.4;
	}
	.ant-spin-dot i {
		background-color : @info-color;
	}
	.ant-spin-text {
		font-size : 2em;
		font-family: @font-family-title;
		margin-top : 14px;
		color : @info-color;
	}
}
#tunjai_content {
	position: relative;
	width : fit-content;
	min-width: 100%;
}
</style>
