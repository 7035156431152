import '@src/vue_bootstrap.js'
import Vue from "vue";
import router from '@router'
import store from "@store/store"
import { mapActions } from "vuex"
import { deviceEnquiry, DEVICE_TYPE } from '@utils/deviceEnquiry.js'
import '@design/index.less'
import "@components/_globals.js"
import App from "./App.vue"
import i18n from "@src/i18n/index.js"


new Vue({
  router,
  store,
  i18n,
  mounted() {
    const myApp = this;
    deviceEnquiry(deviceType => {
      myApp.changeDeviceType(deviceType);
      if(deviceType === DEVICE_TYPE.TABLET && window.location.pathname === '/'){
        router.replace({name:'onfield/create'})
      }
    })
  },
  methods : {
    ...mapActions('app',['changeDeviceType'])
  },
  render: h => h(App)
}).$mount("#app");
