export const COMPANY_ID = {
	DECK: 1,
	HGR: 2,
	FLAGSHIP: 3,
	CHAIN_DEALER: 4,
	EXTERNAL_DEALER: 5,
	SERVICE_CENTER: 6,
}

export const COMPANY_STATUS = {
	ACTIVE: 'active',
}
