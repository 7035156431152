<i18n locale="th" lang="yaml" >
to.refresh : "ลองอีกรอบ"
to.home : "กลับหน้าแรก"
to.login : "Login"
</i18n>

<template>
	<div class="error-container">
		<div class="error-card">
      <div class="error-code">
				<slot name="code">
					ERROR
				</slot>
      </div>
      <div class="error-code-description">
        {{codeDescription}}
      </div>

      <a v-if="displayLocation" :href="displayLocation" class="error-location">
        {{displayLocation}}
      </a>
      <div v-if="errorDetail" class="error-detail">
        {{errorDetail}}
      </div>

      <div class="error-action">
        <a-button v-if="showRefresh" icon="reload" @click="goRefresh">
          {{$t('to.refresh')}}
        </a-button>
        <a-button v-if="isLoggedIn" :href="homeLink" type="info" icon="home">
          {{$t('to.home')}}
        </a-button>
        <a-button v-else :href="loginLink" type="info" icon="user">
          {{$t('to.login')}}
        </a-button>
      </div>
    </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
export default {
	mixins : [LoginInfoMixin] ,
	props : {
		codeDescription : {
			type : String,
      default : null,
		} ,
		location : {
			type : String,
			default : null
		} ,
		errorDetail : {
			type : [String,Boolean],
			default : false,
		} ,
		showRefresh : {
			type : Boolean,
			default : false
		}
  } ,
	computed: {
		...mapGetters('app',['changeDefaultPathByDevice']),
    homeLink() {
      return this.$router.resolve({name:this.changeDefaultPathByDevice}).href
    } ,
    loginLink() {
      return this.$router.resolve({name:'login'}).href
    } ,
    displayLocation() {
      if (this.$notEmpty(this.location))
        return this.location
      else if (this.$notEmpty(this.$route.query.from))
        return window.location.origin + this.$route.query.from
      else
        return window.location.origin
    }
  } ,
  methods : {
    goRefresh() {
      if (this.displayLocation) {
        // Use window location instead of $router push to force reload
        window.location = this.displayLocation
      } else {
        this.$router.go()
      }

    }
  }
}
</script>

<style lang="less" scoped>
.error-container {
  margin : 0 16px;
}
.error-detail {
	text-align : left;
	font-size : 13px;
	color : @text-muted;
	margin : 32px 24px 32px;
	padding : 8px 16px;
	border : 1px dashed @text-muted;
	border-radius : @border-radius-base;
}
.error-location {
	font-size : 14px;
	margin-top : 32px;
}

.error-action {
  margin-top : 16px;
  a {
    min-width : 200px;
  }
}
.error-card {
  box-shadow: 0 15px 15px -10px rgba(0,0,0,.1);
  max-width : 750px;
  width : 100%;
  background : @white;
  margin : 64px auto 32px;

  text-align : center;
  font-family: @font-family-title;
  font-size : 1.5em;
  padding : 16px 24px 42px;
}
.error-code {
  font-size : 6em;
  font-weight: 100;
}
</style>
