import ApiError from "./ApiError";
import {} from "@utils/stringUtil"
export default class ModelError extends ApiError {
	constructor(original,errorResponse) {
		super(original,errorResponse);
		this.model_errors = errorResponse.model_errors;
	}

	getFieldErrorsByModelKey(key) {
		return this.model_errors.find((m) => 'model_key' in m && m.model_key == key)
	}

	hasModelKey(key) {
		return this.model_errors.findIndex((m) => 'model_key' in m && m.model_key == key) !== -1
	}

	static createFromValidation(component,modelErrors,message=null) {
		message = component.$t('common.error.validate_error')
		const error = new Error()
		const convert = {}
		for (const key in modelErrors) {
			convert[key] = {
				model_name : key ,
				field_errors : [modelErrors[key]]
			}
		}
		const response = {
			code : 400 ,
			model_errors : convert
		}
		return new ModelError(error,response)
	}

}
