import Vue from "vue"
import axios from "axios"

export const state = {
	lots : [] ,
}
export const getters = {
	getLotsByDroneModelId: (state) => (droneModelId) => {
		if (!droneModelId) return []

		return state.lots.filter(lot => lot.droneModelId === droneModelId);
	},
}
export const mutations = {
	GET_FROM_LS(state) {
		state.lots = Vue.ls.get('lots',[]);
	} ,
	UPDATE_LOTS(state,payload) {
		state.lots = payload.lots;
		Vue.ls.set('lots',payload.lots,30*60*1000)
	}
}
export const actions = {
	init({commit,dispatch}) {
		commit('GET_FROM_LS')
		dispatch('fetchLots')
	} ,
	async fetchLots({commit,rootGetters}){
		try {
			const response = await axios.get("/api/drone-lots")
			const lots = response.data.data.lots;
			commit('UPDATE_LOTS',{lots})
		} catch (error) {
			console.log("Found error during request lots ",error)
		}
	},
}
