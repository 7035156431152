<script>
import { DEVICE_TYPE } from '@utils/deviceEnquiry'
import { mapState } from 'vuex'

export default {
	computed : {
		isMobile () {
      return this.device === DEVICE_TYPE.MOBILE
    },
    isDesktop () {
      return this.device === DEVICE_TYPE.DESKTOP
    },
    isTablet () {
      return this.device === DEVICE_TYPE.TABLET
		} ,
		...mapState('app',['device'])
	}
}
</script>
