<template>
	<div class="page-header">
		<div class="page-content">
			<div class="page-title">
				{{title}}
			</div>
			<div v-if="showDescription" class="page-description">
				{{description}}
			</div>
			<div v-if="hasDescriptionSlot" class="page-description">
				<slot name="description" />
			</div>
		</div>
		<div v-if="hasActionSlot" class="page-actions">
			<slot name="action" />
		</div>
	</div>
</template>

<script>
import {isStringEmpty} from "@utils/stringUtil.js"

export default {
	props: {
		title: {
			type : String ,
			default : undefined
		} ,
		description : {
			type : String ,
			default : ''
		} ,
	} ,
	computed: {
		showDescription() {
			return !isStringEmpty(this.description);
		} ,
		hasActionSlot() {
			return this.$slots.action;
		} ,
		hasDescriptionSlot() {
			return this.$slots.description;
		}
	} ,
}
</script>

<style lang="less" scoped>
.page-header {
	background : white;
	padding : 16px 32px;
	margin-bottom : 16px;
	display : flex;
	flex-wrap : nowrap;
	align-items: flex-start;

	.mobile & {
		padding : 12px 16px;
	}
}

.page-title {
	font-size : 2em;
	font-family : @font-family-title;
	font-weight: 600;
	color : @primary-color;
	.mobile & {
		font-size : 1.4em;
	}
}
.page-description {
	margin-top : 4px;
	font-size : 0.9em;
	color : @text-muted
}
.page-content {
	flex-grow: 1;
}
.page-actions {
	margin-left: 8px;

	.mobile & {
		margin-bottom : 8px;
		text-align: right;
	}
}
</style>
