import axios from "axios"
import store from "@store/store"
import FieldError from "./errors/FieldError";
import ApiError from '@utils/errors/ApiError'
import get from "lodash/get"
import LoginRequiredError from "./errors/LoginRequiredError";
import ServerRequestError from "./errors/ServerRequestError";
import ModelError from "./errors/ModelError";

export const TIMEOUT_FILE_DOWNLOAD = 600000
export const TIMEOUT_FILE_UPLOAD = 300000
export function axiosInit() {
	axios.defaults.timeout = 120000
	axios.interceptors.response.use(response => response, axiosErrorHandler)
}
function refreshToken(orgRequest,error,responseData) {
	orgRequest._retry = true;
	return new Promise((resolve,reject) => {
		store.dispatch('auth/refreshToken').then((refreshResult) => {
			if (refreshResult === false) {
				store.commit("auth/CLEAR_AUTH")
				reject(new LoginRequiredError(error,responseData))
			} else {
				orgRequest.headers.Authorization = 'Bearer '+refreshResult;
				if (orgRequest.tunjai_validate)
					resolve(true);
				else
					resolve(axios.request(orgRequest))
			}
		}).catch((error) => {
			store.commit("auth/CLEAR_AUTH")
			reject(new LoginRequiredError(error,responseData));
		})
	})
}
export function axiosErrorHandler(error) {
	let rejectError = error;
	const responseData = get(error,"response.data")
	const orgRequest = error.config;
	const responseType = get(error,"response.config.responseType");
	const errorStatus = get(error,"response.status");

	if (responseType == 'blob') {
		// for file download via API
		if (errorStatus == 401 && !orgRequest._retry) {
			return refreshToken(orgRequest,error,responseData);
		} else {
			return Promise.reject(rejectError);
		}
	}

	if (responseData && responseData.result === 'error') {
		// API Error
		if (responseData.field_errors) {
			rejectError = new FieldError(error,responseData);
		} else if (responseData.model_errors) {
			rejectError = new ModelError(error,responseData);
		} else if (error.response.status == 401) {
			// Unauthorized access
			console.log("Unauthorized Access")
			if (responseData.need_refresh && !orgRequest._retry) {
				return refreshToken(orgRequest,error,responseData);
			} else {
				store.commit("auth/CLEAR_AUTH")
				rejectError = new LoginRequiredError(error,responseData);
			}
		} else {
			rejectError = new ApiError(error,responseData);
		}
	} else {
		if (!error.response && error.request) {
			// Response isn't received (maybe server error)
			rejectError = new ServerRequestError(error)
			console.log("Server Error during call API server : "+JSON.stringify(error));
		} else {
			// Something very wrong
			console.log("Found Error during call API server : "+JSON.stringify(error));
		}
	}
	return Promise.reject(rejectError);
}


const basicAxios = axios.create({
	auth : {
		username : process.env.VUE_APP_CLIENT_NAME,
		password : process.env.VUE_APP_CLIENT_SECRET,
	} ,
});
basicAxios.interceptors.response.use(response=>response,axiosErrorHandler);

// axios instance for refresh token , prevent it from error handler
const refreshAxios =  axios.create({
	auth : {
		username : process.env.VUE_APP_CLIENT_NAME,
		password : process.env.VUE_APP_CLIENT_SECRET,
	},
});
export {basicAxios,refreshAxios}
