<template>
	<component :is="component" v-if="component" v-bind="payload" :location="location"/>
	<router-view v-else />
</template>

<script>
export default {
	data () {
		return {
				component: false,
				payload: null,
				location : null,
		};
	},
	watch: {
		'$route' () {
				this.component = false;
		}
	},
	created () {
		window.eventBus.$on('page-error', (location, payload) => {
			const matchedComponents = this.$router.getMatchedComponents(location)
			if (matchedComponents.length > 0) {
				this.component = matchedComponents[matchedComponents.length-1]
				this.payload = payload
			} else {
				this.component = false
				this.payload = null
			}
			this.location = window.location.href
			this.$emit("page-error",payload);
		})
	} ,
	beforeDestroy() {
		window.eventBus.$off('page-error')
	}
};
</script>
