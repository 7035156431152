<template>
	<ConfigProvider :locale="locale">
	<div :class="['container',device]">
    <div id="tunjai_content" class="main">
	  	<router-view />
    </div>
    <footer id="login_privacy_policy_text" class="footer">
      HiveGround Co.,Ltd © Copyright {{copyright}}
      <div class="privacy-policy">
        <my-router-link id="login_privacy_policy_link" class="privacy-policy" name="privacy" no-param>
          {{$t('common.privacy.policy')}}
        </my-router-link>
		  </div>
    </footer>
	</div>
	</ConfigProvider>
</template>


<script>
import LayoutMixin from "@mixins/LayoutMixin.vue"
export default {
  mixins : [LayoutMixin] ,
}
</script>

<style lang="less" scoped>
  .container {
    position: relative;
    padding : 100px 0px 144px;
    min-height: 100%;
    text-align: center;

    background-image : url("@{image-dir}/bg_login.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    .main {
      padding : 0 16px;
      height : 100%;
    }

    &.mobile {
      padding-top : 24px;
      background-size: contain;
    }
  }
  footer {
    position : absolute;
    bottom : 0;
    width : 100%;
    padding : 16px 16px 16px;
    background-color : @footer-bg;
    color : @footer-color;
    border-top : solid 1px @border-color-base;
  }
  #tunjai_content {
    position: relative;
    width : fit-content;
	  min-width: 100%;
  }
  .privacy-policy {
    font-size: 1.1em;
    color: #FFF;
    text-decoration: underline;
    .mobile & {
      font-size: 1em;
    }
  }
  a:hover {
    color: #0f85ff;
  }
</style>
